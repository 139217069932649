import React from "react";
import { withRouter } from 'react-router-dom'

import { ChipSet, Chip } from "@material/react-chips";
import MaterialIcon from "@material/react-material-icon";

import * as serviceWorker from "serviceWorker"
import * as cache from "utils/cache"

class JUpgradeNotice extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = { open: false }

    this.commitUpgrade = this.commitUpgrade.bind(this)
    this.checkUpdates = this.checkUpdates.bind(this)
  }

  componentWillMount() {
    const { history } = this.props

    this.unsubscribeFromHistory = history.listen(this.checkUpdates)
    this.checkUpdates()
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory()
  }

  checkUpdates() {
    if (!cache.getValue('version')) {
      // Version hasn't been set

      fetch("/version.json", {
        method: "GET",
        headers: {
          Accept: "*/*"
        }
      })
        .then(response => response.json())
        .then(responseJSON => {
          cache.setValue("version", responseJSON.version)
        })
    } else {
      // Version has been set

      const cacheExpireDate = cache.getValue('version-date')
        ? cache.getValue('version-date') * 1 +
          process.env.REACT_APP_VERSION_EXPIRE * 1
        : Date.now()

      if (Date.now() > cacheExpireDate) {
        fetch("/version.json", {
          method: "GET",
          headers: {
            Accept: "*/*"
          }
        })
          .then(response => response.json())
          .then(responseJSON => {
            if (cache.getValue('version') < responseJSON.version) {
              this.setState({ open: true })
            }
          })
      }
    }
  }

  commitUpgrade() {
    cache.removeValue("version")

    serviceWorker.register({
      onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
          waitingServiceWorker.addEventListener("statechange", event => {
            if (event.target.state === "activated") {
              window.location.reload()
            }
          })
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
        }
      }
    })

    this.setState({ open: false }, () => {
      window.location.reload()
    })
  }

  render() {
    if (!this.state.open) return null

    return (
      <div className="upgradeNotice">
        <ChipSet>
          <Chip
            leadingIcon={<MaterialIcon icon="autorenew" />}
            label="New App Version Available"
            onClick={this.commitUpgrade}
          />
        </ChipSet>
      </div>
    )
  }
}

export default withRouter(JUpgradeNotice)
