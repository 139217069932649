/*
    Jujama Component: JActionMessage

    Simple snackbar notification for when actions take place. E.g. Added a session to schedule, sent a message, etc.

    Usage:
    <JActionMessage />
    
    function updateActionMessage accepts a text string, which will open the snackbar, automatically close after 4 seconds, and then reset the snackbar

*/
import React from "react";

import {Snackbar} from '@material/react-snackbar';

export function updateActionMessage(text) {
    this.setState({open: true, text: text})
}

class JActionMessage extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            text: "default text"
        }

        // eslint-disable-next-line no-func-assign
        updateActionMessage = updateActionMessage.bind(this)
        this.onClose = this.onClose.bind(this)
    }

    onClose() {
        this.setState({open: false})
    }

    render() {
        return(
            <div className="appMessage">
                <Snackbar open={this.state.open} message={this.state.text} onClose={this.onClose} timeoutMs="4000" actionText="dismiss" />
            </div>
        )
    }

}
export default JActionMessage;