/*
    Jujama Component: JLoading

    Loading indicator like Facebook.

    Usage:
    <JLoading type="list" />
    
    Required:
    type (string)
    
    Options:
    
    Default:
        shell

*/

import React from "react";
import ContentLoader from 'react-content-loader'

const JLoading = (props) => {
    let types = []
    if (!props.type) props.type = 'shell'

    types['shell'] = { /* Application shell, like top bar and tabs */
        height: 300,
        layout: (<React.Fragment>
            <rect name="top-app-bar" x="0" y="0" rx="0" ry="0" width="100%" height="56" />
            <rect name="tabs" x="10" y="75" rx="0" ry="0" width="42.5%" height="50" />
            <rect name="tabs" x="50%" y="75" rx="0" ry="0" width="42.5%" height="50" /></React.Fragment>)}
    types['agenda'] = { /* Agenda list view */
        height: 600,
        layout: (<React.Fragment>
            <rect name="card" x="5" y="10" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="220" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="430" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="640" rx="0" ry="0" width="95%" height="190" /></React.Fragment>)}
    types['list'] = { /* Any list */ 
        height: 800, 
        layout: (<React.Fragment>
            <rect name="card" x="5" y="10" rx="0" ry="0" width="95%" height="125" />
            <rect name="card" x="5" y="155" rx="0" ry="0" width="95%" height="125" />
            <rect name="card" x="5" y="300" rx="0" ry="0" width="95%" height="125" />
            <rect name="card" x="5" y="445" rx="0" ry="0" width="95%" height="125" /></React.Fragment>)}
    types['session'] = { /* A single session */
        height: 800, 
        layout: (<React.Fragment>
            <rect name="card" x="5" y="10" rx="0" ry="0" width="95%" height="125" /></React.Fragment>)}
    types['socialfeed'] = { /* Agenda list view */
        height: 600,
        layout: (<React.Fragment>
            <rect name="card" x="5" y="10" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="220" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="430" rx="0" ry="0" width="95%" height="190" />
            <rect name="card" x="5" y="640" rx="0" ry="0" width="95%" height="190" /></React.Fragment>)}
    types['profile'] = { /* A single profile */
        height: 800, 
        layout: (<React.Fragment>
            <rect name="avatar" x="5" y="10" rx="50" ry="50" width="60" height="60" />
            <rect name="name" x="80" y="15" rx="3" ry="3" width="100" height="12" />
            <rect name="profile" x="5" y="150" rx="0" ry="0" width="95%" height="50%" /></React.Fragment>)}
    
    return (
    <ContentLoader
    height={types[props.type].height}
    primaryColor="#eeeeee">
      {types[props.type].layout}
    </ContentLoader>)
}
export default JLoading;