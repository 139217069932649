import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import JActionMessage from 'components/JActionMessage'
import JUpgradeNotice from 'components/JUpgradeNotice'
import JLoading from 'components/JLoading'

import 'styles/all.scss'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateType,
)

const EventSelector = lazy(() => import('pages/EventSelector'))
const Login = lazy(() => import('pages/Auth/Login'))
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'))
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const Agenda = lazy(() => import('pages/Agenda'))
const Session = lazy(() => import('pages/Session'))
const Profiles = lazy(() => import('pages/Profiles'))
const Sponsors = lazy(() => import('pages/Sponsors'))
const Exhibitors = lazy(() => import('pages/Exhibitors'))
const Profile = lazy(() => import('pages/Profile'))
const SocialFeed = lazy(() => import('pages/SocialFeed'))
const SocialFeedPost = lazy(() => import('pages/SocialFeedPost'))
const SocialFeedNew = lazy(() => import('pages/SocialFeedNew'))
const Messages = lazy(() => import('pages/Messages/Messages'))
const ComposeMessage = lazy(() => import('pages/Messages/ComposeMessage'))
const Chat = lazy(() => import('pages/Messages/Chat'))
const About = lazy(() => import('pages/About'))

const AuthLayoutRoute = lazy(() => import('pages/layouts/Auth.layout'))
const DefaultLayoutRoute = lazy(() => import('pages/layouts/Default.layout'))
const StandardLayoutRoute = lazy(() =>
  import('pages/layouts/StandardLayout/StandardLayout'),
)

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Suspense fallback={<JLoading type="shell" />}>
              <Route path="/" exact component={EventSelector} />
              <AuthLayoutRoute path="/login" exact component={Login} />
              <AuthLayoutRoute path="/login/:KeyName" component={Login} />
              <AuthLayoutRoute
                path="/forgot-password/:KeyName"
                component={ForgotPassword}
              />
              <AuthLayoutRoute
                path="/reset-password"
                noRedirect
                component={ResetPassword}
              />
              <DefaultLayoutRoute path="/dashboard" component={Agenda} />
              <StandardLayoutRoute
                path="/agenda"
                exact
                searchAlwaysVisible
                component={Agenda}
              />
              <DefaultLayoutRoute
                path="/agenda/session/:KeyName"
                hasParent
                component={Session}
              />
              <DefaultLayoutRoute
                path="/socialfeed"
                exact
                component={SocialFeed}
              />
              <DefaultLayoutRoute
                path="/socialfeed/new"
                exact
                hasParent
                component={SocialFeedNew}
              />
              <DefaultLayoutRoute
                path="/socialfeed/post/:KeyName"
                hasParent
                component={SocialFeedPost}
              />
              <DefaultLayoutRoute path="/schedule" exact component={Agenda} />
              <DefaultLayoutRoute path="/profiles" exact component={Profiles} />
              <DefaultLayoutRoute path="/speakers" exact component={Profiles} />
              <DefaultLayoutRoute path="/sponsors" exact component={Sponsors} />
              <DefaultLayoutRoute
                path="/messages"
                exact
                noSearch
                component={Messages}
              />
              <DefaultLayoutRoute
                path="/messages/new"
                exact
                noSearch
                hasParent
                component={ComposeMessage}
              />
              <DefaultLayoutRoute
                path="/messages/chat/:KeyName"
                exact
                noSearch
                hasParent
                component={Chat}
              />
              <DefaultLayoutRoute
                path="/send-message"
                exact
                noSearch
                hasParent
                componentOptions={{ startsWithPeople: true }}
                component={ComposeMessage}
              />
              <DefaultLayoutRoute
                path="/exhibitors"
                exact
                component={Exhibitors}
              />
              <DefaultLayoutRoute
                path="/exhibitors/exhibitor/:KeyName"
                hasParent
                component={Profile}
              />
              <DefaultLayoutRoute
                path="/sponsors/sponsor/:KeyName"
                hasParent
                component={Profile}
              />
              <DefaultLayoutRoute
                path="/organizations"
                exact
                component={Profiles}
              />
              <DefaultLayoutRoute
                path="/profiles/profile/:KeyName"
                hasParent
                component={Profile}
              />
              <DefaultLayoutRoute
                path="/organizations/organization/:KeyName"
                hasParent
                component={Profile}
              />
              <DefaultLayoutRoute path="/about" noSearch component={About} />
            </Suspense>
          </Switch>
          <JActionMessage />
          <JUpgradeNotice />
        </div>
      </Router>
    )
  }
}

export default App
