/*
    Utilities for retrieving and setting cache

*/

// builds a key string for storage
const createKeyStr = key => {
  if (!key) return false;

  /*
        Keys that do not include conferenceId
            - currentConferenceId
            - version
            - version-date
    */

  // Do not append conference Id for the following
  const storageKey =
    key === "currentConferenceId" ||
    key === "usersSavedEvents" ||
    key === "usersSavedEventsData" ||
    key === "usersSavedEvents-date" ||
    key === "usersSavedEventsData-date" ||
    key === "version" ||
    key === "version-date"
      ? key
      : key + "-" + getValue("currentConferenceId");

  return storageKey;
};

// builds a storage key
const createKey = (key, context) => {
  if (!context) return createKeyStr(key)

  return `${context}__${createKeyStr(key)}`
}

// finds out if a storage key is within the context
const isKeyInContext = (key, context) => key.includes(`${context}__`);

/*
This method could return either an immediate value or a promise
- gets cache value if fetcher is not defined (immediate)
- gets cache value if not expired (immediate)
- fetches the value otherwise, set cache with the fetched value (promise)
*/
export const getValue = (key, fetcher, context) => {
  if (!key) return false;

  const storeKey = createKey(key, context);

  if (!fetcher) {
    // Return the following as strings, rather than parsed JSON
    if (
      (key === "usersSavedEvents" ||
        key === "usersSavedEvents-date" ||
        key === "usersSavedEventsData-date" ||
        key === "UserToken" ||
        key === "UserName" ||
        key === "UserParticipationId" ||
        key === "UserRole" ||
        key === "UserPassword") &&
      localStorage[storeKey]
    )
      return localStorage[storeKey];

    return localStorage[storeKey]
      ? JSON.parse(localStorage[storeKey])
      : false;
  }
  if (!isKeyExpired(key, context)) {
    return getValue(key, null, context);
  }

  return fetcher(fetchedValue => {
    setValue(key, fetchedValue, context);
    return fetchedValue;
  });
};

// Returns true if cache is expired, false if still valid
export const isKeyExpired = (key, context) => {
  if (!key) return false;
  /*
    Cache duration is dependant on key
    Examples:
      - Default cache expire should be 15 minutes
      - Password expire should be 2 weeks
      - Social Feed expire should be 1 minute
      - Chat List should be 1 minute
      
    Check .env file for all expiration durations.
  */
  let cacheExpireDuration;
  switch (key) {
    case "UserPassword":
      cacheExpireDuration = process.env.REACT_APP_PASSWORD_EXPIRE;
      break;
    case "socialfeed":
      cacheExpireDuration = process.env.REACT_APP_SOCIAL_FEED_EXPIRE;
      break;
    case "chats":
      cacheExpireDuration = process.env.REACT_APP_CHATS_EXPIRE;
      break;
    default:
      cacheExpireDuration = process.env.REACT_APP_CACHE_EXPIRE;
      break;
  }

  if (!getValue(key, null, context)) return true; // if the cache doesn't even exists, return that it is expired

  const dateKey = key + "-date";
  const cacheExpireDate = getValue(dateKey, null, context)
    ? getValue(dateKey, null, context) * 1 + cacheExpireDuration * 1
    : Date.now();

  return Date.now() < cacheExpireDate ? false : true;
};

// removes storage item returns true, otherwise false
export const removeValue = (key, context) => {
  if (!key) return false;

  const storageKey = createKey(key, context);
  const storageDateKey = createKey(key + '-date', context);

  if (localStorage[storageKey]) {
    localStorage.removeItem(storageKey);

    // If there is a date key for this key, remove that too
    if (localStorage[storageDateKey]) {
      localStorage.removeItem(storageDateKey);
    }

  } else {
    return false;
  }

  return true;
};

// remove the cache for whole context
export const removeContext = context => {
  if (!context) return false;

  const keysToRemove = Object.keys(localStorage).filter(key => isKeyInContext(key, context));
  keysToRemove.forEach(storageKey => {
    localStorage.removeItem(storageKey);
  })

  return true;
}

// sets value of new key or replaces existing
export const setValue = (key, value, context) => {
  if (!key || !value) return false;

  // If value is object then stringify
  const storageValue =
    typeof value === "string" ? value : JSON.stringify(value);

  // store value
  localStorage.setItem(createKey(key, context), storageValue);

  // store date value was stored
  localStorage.setItem(createKey(key + "-date", context), Date.now());

  return true;
};
